/* eslint-disable max-len */
import { ObservationType } from 'services/enums';
export default {
  accept: 'Accept',
  acceptingInvitation: 'Accepting invitation...',
  connected: 'Connecting ...',
  disconnected: 'Disconnecting ...',
  billing: {
    pageTitle: 'Billing',
    viewPlan: 'View',
    verifyEmail: 'Update',
    resendVerificationCode: 'Resend Code',
    verifyPendingEmail: '{0}: pending verification. Click on edit to verify.',
    editEmail: 'Edit',
    verificationCode: 'Enter the code',
    cancel: 'Cancel',
    changeEmailMessage: 'You will be logged out of the app after you verify your new email. Please use the new email to login again.',
    verificationCodeMessage: 'Sent a 4 digit code to {0} to confirm',
    confirmMessageFirst: 'Your email is successfully updated to {0}.',
    confirmMessageThree: 'You will be signed out in 10 seconds to complete the change. Please use new email to login again.',
    confirmMessageSecond: 'Don\'t forget to set your quick access preference when you login.',
    validationEmail: 'Please enter the new email',
    validationAminCannotChangeEmail: 'You have access to dashboard information. At this time, we do not allow self-service access to update your email as you have access to sensitive data.',
    contactUs: 'Contact us.',
    resendCode: 'Code sent!'
  },
  accountSettings: {
    pageTitle: 'Email Setting',
    newEmail: 'Email',
    verifyEmail: 'Update',
    resendVerificationCode: 'Resend Code',
    verifyPendingEmail: '{0}: pending verification. Click on edit to verify.',
    editEmail: 'Edit',
    verificationCode: 'Enter the code',
    cancel: 'Cancel',
    changeEmailMessage: 'You will be logged out of the app after you verify your new email. Please use the new email to login again.',
    verificationCodeMessage: 'Sent a 4 digit code to {0} to confirm',
    confirmMessageFirst: 'Your email is successfully updated to {0}.',
    confirmMessageThree: 'You will be signed out in 10 seconds to complete the change. Please use new email to login again.',
    confirmMessageSecond: 'Don\'t forget to set your quick access preference when you login.',
    validationEmail: 'Please enter the new email',
    validationAminCannotChangeEmail: 'You have access to dashboard information. At this time, we do not allow self-service access to update your email as you have access to sensitive data.',
    contactUs: 'Contact us.',
    resendCode: 'Code sent!'
  },
  activity: {
    visTitle: 'From {0}',
    visDurationlabel: 'Duration: {0} min',
    visIntensityLabel: 'Intensity Score: {0}'
  },
  activityTitleTab: 'Add Exercise',
  activityLogHeaderDefault: 'Add Exercise',
  activityFormNameLabel: 'Exercise',
  activityFormNotesPlaceholder: 'Add any notes about this exercise',
  activityStartDateTimeLabel: 'Start Time',
  activityDurationLabel: 'Duration',
  and: '{0} and {1}',
  observation: {
    context: {
      contextHeader: 'This data is associated with \'{0}\' task of the {1} pathway.',
      dateAfter: 'Due after {0}',
      dateBefore: 'Due before {0}',
      dateBetween: 'Due between {0} - {1}'
    },
    bodyArms: {
      title: 'Arms',
      logTitle: 'Log Arms',
      noDataText: 'No arms data has been recorded yet.',
      tileNoDataText: 'Add your first arms data'
    },
    bodyChest: {
      title: 'Chest',
      logTitle: 'Log Chest',
      noDataText: 'No chest data has been recorded yet.',
      tileNoDataText: 'Add your first chest data'
    },
    bodyFat: {
      title: 'Body Fat',
      noDataText: 'Connect a device to start seeing your body fat measurements here.'
    },
    bodyHips: {
      title: 'Hips',
      logTitle: 'Log Hips',
      noDataText: 'No hips data has been recorded yet.',
      tileNoDataText: 'Add your first hips data'
    },
    bodyThighs: {
      title: 'Thighs',
      logTitle: 'Log Thighs',
      noDataText: 'No thighs data has been recorded yet.',
      tileNoDataText: 'Add your first thighs data'
    },
    bodyWaist: {
      title: 'Waist',
      logTitle: 'Log Waist',
      noDataText: 'No waist data has been recorded yet.',
      tileNoDataText: 'Add your first waist data'
    },
    bloodOxygen: {
      title: 'Blood Oxygen',
      noDataText: 'Connect a device to start seeing your blood oxygen percentage readings here.'
    },
    bloodGlucose: {
      title: 'Blood Glucose',
      goalTitle: 'Blood Glucose Goal',
      noDataText: 'Start tracking your blood glucose.',
      glucoseGoal: 'Set your blood glucose goal range',
      glucoseRange: 'Blood Glucose range (mg/dL)',
      avgPerDay: 'Avg per day',
      avgPerWeek: 'Avg of last week',
      averageGlucose: 'On average, your glucose level is',
      averagePeriod: 'per day last week',
      unit: 'mg/dL',
      noData: 'No Data',
      inRange: 'In Range'
    },
    bloodKetone: {
      title: 'Blood Ketone',
      noDataText: 'Start tracking your blood ketone.',
      barChartFormat: '{0} mg/dL'
    },
    bloodPressure: {
      title: 'Blood Pressure',
      noDataText: 'Connect a device to start seeing your blood pressure readings here.',
      systolicChartLabel: 'Systolic',
      diastolicChartLabel: 'Diastolic',
      systolicFormLabel: 'Systolic',
      systolicFormSubLabel: 'Top number',
      diastolicFormLabel: 'Diastolic',
      diastolicFormSubLabel: 'Bottom number',
      diastolicValueHigherError: 'Must be lower than Systolic',
      goalPageTitle: 'Blood Pressure Goal',
      goalPageContent: 'Set your blood pressure goal range',
      goalPageSystolicLabel: 'Systolic range (mmHg)',
      goalPageTopNumberLabel: 'Top number',
      goalPageDiastolicLabel: 'Diastolic range (mmHg)',
      goalPageBottomNumberLabel: 'Bottom number',
      goalPageToLabel: 'to',
      currentGoalRange: 'Current Goal Range',
      colon: ':',
      validateRange: 'Must be greater than from value',
      averagePressure: ' On average, your pressure level is',
      averagePeriod: 'per day last week',
      averagePerWeek: 'Avg of last week',
      unit: 'mmHg',
      noData: 'No Data'
    },
    heartRate: {
      title: 'Heart Rate',
      logTitle: 'Log Heart Rate',
      noDataText: 'No heart rate data has been recorded yet.',
      tileNoDataText: 'Add your first heart rate'
    },
    heartRateVariability: {
      title: 'Heart Rate Variability'
    },
    height: {
      title: 'Height',
      logTitle: 'Log Height',
      noDataText: 'No height data has been recorded yet.',
      tileNoDataText: 'Add your first height'
    },
    immunization: {
      title: 'Immunizations',
      tileNoDataText: 'No Immunizations have been recorded yet.'
    },
    insulin: {
      title: 'Insulin',
      noDataText: 'Connect a device to start seeing your insulin readings here.'
    },
    labResult: {
      title: 'Diagnostic Reports',
      tileNoDataText: 'No diagnostic reports have been recorded yet.',
      viewEncounters: 'View by encounter',
      viewTrends: 'View trends',
      component: 'Component',
      value: 'Your value',
      noTrends: 'No lab trends found.'
    },
    ehrLabResult: {
      title: 'EHR Lab Results',
      tileNoDataText: 'No lab results have been recorded yet.'
    },
    medication: {
      medication: 'Medication Notes'
    },
    nutritions: {
      title: 'Nutrition',
      noDataText: 'Connect a device to start seeing your daily Nutrition here.',
      tileTitle: 'Daily Nutrition',
      barChartFormat: '{0} Calories',
      carbohydrate: 'Carbs',
      protein: 'Protein',
      fat: 'Fat',
      calories: 'calories',
      cal: 'Cal',
      averageCalories: 'Avg cal / day',
      today: 'Today',
      addGoal: 'Add Goal',
      changeGoal: 'Change Goal',
      currentGoal: 'Current Goal is',
      carbsGoal: 'carbs',
      proteinGoal: 'protein',
      fatGoal: 'fat',
      calGoal: 'Cal goal',
      goal: 'goal',
      actual: 'actual',
      macrosMessage: 'Macros must equal 100%',
      averageCal: 'On average, you consumed',
      macroGoal: 'Macro goals',
      calorieGoal: 'Calorie goal (cal/day)',
      nutritionGoal: 'Set daily nutrition goal.',
      caloriesGoal: 'Calories',
      week: 'week',
      perDay: 'per day last week.',
      macroGoalPage: 'Macro goal',
      calorieObservationGoal: 'Calorie goal (cal/day)'
    },
    peakFlow: {
      title: 'Peak Flow',
      noDataText: 'Connect a device to start seeing your peak flow readings here.',
      peakFlowFormLabel: 'Peak Flow (L/min)'
    },
    sleep: {
      title: 'Sleep',
      noDataText: 'Connect a device to start seeing your sleep readings here.',
      hoursLabel: 'hr',
      minutesLabel: 'min'
    },
    steps: {
      title: 'Steps',
      noDataText: 'Connect a device to start seeing your daily steps here.',
      tileTitle: 'Daily Steps',
      barChartFormat: '{0} steps',
      averagePerDay: 'Avg per day',
      averageData: 'Avg for the week',
      noData: 'No Data',
      stepsRange: 'Steps per day'
    },
    temperature: {
      title: 'Temperature',
      logTitle: 'Log Temperature',
      noDataText: 'No temperature data has been recorded yet.',
      tileNoDataText: 'Add your first temperature'
    },
    weight: {
      title: 'Weight',
      logTitle: 'Log Weight',
      noDataText: 'No weight data has been recorded yet.'
    },
    floorsClimbed: {
      title: 'Floors Climbed',
      logTitle: 'Floors Climbed',
      noDataText: 'No Floors Climbed data has been recorded yet.'
    }
  },
  setGoal: 'Set your goal here.',
  checkEmail: 'Please check your email. We have sent an email to {0}.',
  customizeDashboard: 'Shortcuts',
  sendingConfirmationEmail: 'Sending another confirmation email.',
  daysShortLabel: 'd',
  hoursShortLabel: 'h',
  minutesShortLabel: 'm',
  secondsShortLabel: 's',
  daysLabel: 'days',
  hoursLabel: 'hours',
  minutesLabel: 'minutes',
  secondsLabel: 'seconds',
  activityIntensityLabel: 'Intensity',
  low: 'Light',
  medium: 'Moderate',
  high: 'Vigorous',
  lowActivityHelper: 'You can sing a song',
  mediumActivityHelper: 'You can hold a conversation',
  highActivityHelper: 'You can only say a few words',
  add: 'Add',
  agree: 'I agree',
  app: 'Calcium',
  aspartof: 'as part of',
  back: 'Back',
  bmiCategoryNormal: '(Normal)',
  bmiCategoryObese: '(Obese)',
  bmiCategoryOverweight: '(Overweight)',
  bmiCategoryUnderweight: '(Underweight)',
  bmiDetailsBmi: 'Recent BMI',
  bmiTitle: 'BMI',
  bmiHeader: 'Body Mass Index',
  bmiNoDataText: 'Both height and weight data are required to calculate BMI.',
  bodyMeasurementsHeader: 'Body Measurements',
  browserInformationDisclaimer: 'The following information is being gathered for diagnostic use.',
  cancel: 'Cancel',
  close: 'Close',
  continue: 'Continue',
  skip: 'Skip',
  complete: 'Complete',
  next: 'Continue to set goal',
  copyLink: 'Copy Link',
  copyright: 'Calcium LLC',
  createSelfProfileTitle: 'Create Your Profile',
  createOtherProfileTitle: 'Create Profile',
  currentSelection: 'Current Selection',
  activityText: 'activity',
  dashboard: {
    allResults: 'View All',
    sharing: 'Sharing',
    healthInfo: 'Healthcare Info',
    data: {
      header: 'Health Data'
    },
    dataConnections: {
      header: 'Data Sources',
      tile: 'Devices & Data'
    },
    pathways: {
      activeHeader: 'My Pathways',
      activeHeader_v1dashboard: 'My activities for today',
      completedHeader: 'Completed Pathways',
      header: 'Explore Pathways',
      description: 'Our personalized health improvement programs.',
      nextTask: 'Next Task',
      dueToday: 'Due Today'
    },
    clickToSee: 'Click to see ',
    newDashboard: 'New Dashboard'
  },
  healthInfo: {
    title: 'Health Info',
    provider: { title: 'Provider' },
    insurance: { title: 'Insurance' },
    addProvider: {
      name: 'Full Name of Primary Physician',
      orgName: 'Provider Organization Name',
      address: 'Provider Address',
      email: 'Provider Email',
      phoneNumber: 'Provider Phone Number',
      notes: 'Notes'
    },
    addInsurance: {
      name: 'Name of Insurer',
      personName: 'Name of Primary Insured Person',
      id: 'ID',
      groupID: 'Group ID',
      careType: 'Care Type',
      expirationDate: 'Expiration Date',
      insurerPhNo: 'Insurer Phone Number',
      notes: 'Notes'
    }
  },
  healthIndex: {
    healthIndexToday: 'My HealthIndex',
    improveAccuracyOfHealthIndex: 'Inform your My HealthIndex score each day by completing these tasks:',
    isProfileAccurate: 'Ensure your profile is accurate',
    ageAndGender: 'Age and gender are important',
    connectYourDevice: 'Connect your health devices',
    activityTrackers: 'Activity trackers, Smart scales, Blood pressure cuffs, etc.,​',
    smartScales: 'Smart scales (withings, etc)​',
    bloodPressure: 'Blood pressure cuffs​',
    addMoreInformation: 'Add information about yourself',
    heightWeight: 'Height, Weight, Body Measurements',
    pastMonthTitle: 'Past month',
    todayTitle: 'Today',
    healthIndexTooltip: 'HealthIndex'
  },
  // To Do Beta User
  healthData_v1Dashboard: {
    title: 'My health data',
    healthIndex: 'HealthIndex',
    exercise: 'exercise this week',
    litersPerMinute: 'liters/min',
    calories: 'calories',
    myRecords: 'My health records',
    addOtherData: 'Add other data',
    generateHealthIndex: 'Generate my HealthIndex',
    trackSteps: 'Sync steps',
    trackExercise: 'Track exercise',
    addInfo: 'Add information',
    addImmunization: 'Add immunization info'
  },
  medication_v1Dashboard: {
    setMedicationTitle: 'Set Medications/Supplement Reminders',
    tookItTitle: 'I took it'
  },
  dateDisplayToday: 'Today',
  dateDisplayTomorrow: 'Tomorrow',
  dateDisplayYesterday: 'Yesterday',
  dateDisplayDayFormat: 'dddd',
  decline: 'Decline',
  deleted: 'Deleted',
  deleting: 'Deleting...',
  devices: {
    connectedDevicesTitle: 'Connected Devices',
    connectButton: 'Connect a device',
    errorMessage: 'Unable to prepare for device and data connections',
    explanation: 'Connect to one of many compatible devices:',
    deviceNames: 'Apple watch, Fitbit, Garmin…',
    seeAllDevices: 'See all device options:',
    dotDescription: 'Note: The colored dot indicates the data came from a third-party.',
    overviewTitle: 'Devices & Data',
    connectError: 'Failed to open device manager. Click to try again.',
    syncTitle: 'Automatically Sync Data',
    needADeviceQuestion: 'Need a device that works with Calcium?',
    marketplaceLink: 'Get a compatible device here.',
    healthKitExplanation: 'Click here to connect or disconnect your Apple Health account.',
    healthKitButton: 'Apple Health',
    healthKitButtonExplanationFirst: 'Please note,',
    healthKitButtonExplanation: ' you will only be able to disconnect from Apple Health via your Apple Device.',
    googleFitButtonExplanation: ' you will only be able to connect/disconnect from Google Fit via your Android Device.',
    disconnectExplanation: 'Are you sure you want to disconnect your device? Disconnecting your device will cause data to stop syncing.',
    disconnectConfirmationTitle: 'Disconnect device?',
    disconnect: 'Disconnect',
    connectDevices: 'Connect Devices',
    connectLink: 'Connect',
    deviceConnectExplanation: 'No Device detected. Please connect a device to join challenge.',
    syncIsInProgress: 'Data sync is in progress...'

  },
  privateProfile: 'Private Profile',
  // privateProfileDescription:'This information will never be publicly visible and will only be shared with those you designate.',
  privateProfileDescription: 'Your private profile information will never be publicly visible and will only be shared with providers and members you designate.',
  publicProfile: 'Public Profile',
  displayName: 'Display name',
  firstName: 'First name',
  done: 'Done',
  edit: 'Edit',
  editProfile: 'Edit Profile',
  error: 'An error occurred',
  errorLoadProfile: 'Sorry, we were unable to load your profile.  Please check your internet connection and try again.',
  errorPasswordError: 'Password is not allowed, it might be too common',
  errorPasswordStrengthError: 'Password must be at least 8 characters',
  errorUserExistsError: 'Email already registered',
  errorInvalidEmailFormat: 'Invalid email format',
  errorCodeMessage: {
    inviteAlreadyExists: 'A sharing invite already exists for the user with this email',
    inviteSelf: 'Cannot invite self',
    inviteUsed: 'This invite is no longer valid',
    inviteExistingRelationship: 'The user with this email already has access to this profile'
  },
  feedbackEmailSubject: 'Calcium Feedback',
  formAvatarLabel: 'Avatar',
  formBirthdateLabel: 'Date of Birth',
  formBloodGlucoseLabel: 'Blood Glucose (mg/dL)',
  formBloodKetoneLabel: 'Blood Ketone (mg/dL)',
  formBodyChestLabel: 'Chest (inches)',
  formBodyChestPlaceholder: 'Example: 30',
  formBodyHipsLabel: 'Hips (inches)',
  formBodyHipsPlaceholder: 'Example: 30',
  formBodyThighsLabel: 'Thighs (inches)',
  formBodyThighsPlaceholder: 'Example: 30',
  formBodyWaistLabel: 'Waist (inches)',
  formBodyWaistPlaceholder: 'Example: 30',
  formBodyArmsLabel: 'Arms (inches)',
  formBodyArmsPlaceholder: 'Example: 30',
  formCancel: 'Cancel',
  takeMeLater: 'I\'ll check it out later',
  formContinue: 'Continue',
  formDayLabel: 'Day',
  formEmailLabel: 'Email',
  formResetEmailLabel: 'Email',
  formGenderLabel: 'Gender',
  formHeartRateLabel: 'Heart Rate (bpm)',
  formHeartRatePlaceholder: 'Example: 120',
  formHeightFeetLabel: 'Feet',
  formHeightInchesLabel: 'Inches',
  formImmunizationLabel: 'Immunization Type',
  formLabResultLabel: 'Diagnostic Report Type',
  formLast4Ssn: 'Last 4 digits of SSN',
  medicationDoses: 'Doses',
  noMedicationDoses: 'No medications doses matching your search have been recorded.',
  noMedicationScheduled: 'No medications scheduled for this day.',
  medications: {
    deleteButton: 'Delete Medication',
    deleteWarning: 'Warning: Deleting this medication will also delete all of its recorded doses'
  },
  medicationDoseLogHeaderDefault: 'Medication Dose',
  medicationDoseLogHeader: 'Add {0} Dose',
  medicationDoseAddTitleTab: 'Add Medication Dose',
  medicationDosageCallToAction: 'Add Dose',
  medicationMostRecentDose: '{0} taken {1}',
  medicationNoTypicalDose: 'No typical dose was found',
  medicationFormTypicalDose: 'Typical Dose:',
  medicationLogHeader: 'Add Medication',
  medicationEditHeader: 'Edit Medication',
  medicationAddTitleTab: 'Add Medication',
  medicationEditTitleTab: 'Edit Medication',
  medicationFormNameLabel: 'Medication Name',
  medicationFormStartLabel: 'Medication schedule starts from',
  medicationFrequencyLabel: 'When do you take this dose?',
  medicationAddTimingLabel: 'Add timing',
  medictaionTimeSlotErrorLabel: 'Atleast one schedule should be entered',
  medictaionSameTimeSlotErrorLabel: 'Schedule times cannot be repeated',
  medicationTimeSlotInfoLabel: 'Please click on "add timing" to set the time',
  medicationTimingLabel: 'Set Timing',
  medicationTimingSetLabel: 'Set',
  medicationFormNotesPlaceholder: 'Add any notes about this medication',
  medicationFormFrequencyPlaceholder: 'Add any Frequency about this medication',
  medicationFormTypicalDosePlaceholder: 'Add your typical dose',
  medicationFormTypicalDoseLabel: 'How many taken each dose? (optional)',
  medicationDoseFormlDoseLabel: 'Dose (optional)',
  medicationDoseFormlDosePlaceholder: 'Dose Taken',
  medicationDoseFormSubmit: 'Add Medicine',
  medicationDoseOverdue: 'Overdue',
  medicationDoseTakenow: 'Take Now',
  medicationDoseTaken: 'Taken',
  medicationDoseSkipped: 'Skipped',
  medicationDoseTake: 'Take',
  medicationDoseSkip: 'Skip',
  morning: 'Morning',
  afternoon: 'Afternoon',
  evening: 'Evening',
  nighttime: 'Nighttime',
  formMonthLabel: 'Month',
  formNameLabel: 'Preferred Name',
  formNotesLabel: 'Notes (optional)',
  formNotesPlaceholder: 'What was going on at the time?',
  formPasswordLabel: 'Password',
  formPasswordRequirementLabel: 'Password (8 character minimum)',
  formPrivacyPolicyAgreeText: 'By clicking "{0}" you are accepting our {1} and {2}.',
  formProfileHeader: 'Profile',
  formProfileSave: 'Save Profile',
  formSave: 'Save',
  formSignInButton: 'Sign In',
  formContinueButton: 'Continue',
  formSignInHeader: 'Sign in to Calcium app',
  formSignInForgotPassword: 'Forgot password?',
  formSignInResetPasswordLink: 'Reset',
  formSignInJoin: 'Create an account.',
  formSignInJoinText: 'New to Calcium?',
  formSubmit: 'Submit',
  formTemperatureLabel: 'Temperature (ºF)',
  formTemperaturePlaceholder: 'Temperature',
  formTimestampLabel: 'Timestamp',
  formUpdate: 'Update',
  formWeightLabel: 'Weight (lbs)',
  formWeightPlaceholder: 'Weight',
  formYearLabel: 'Year',
  genderFemale: 'Female',
  genderMale: 'Male',
  genderOther: 'Other',
  genderUnknown: 'Unknown',
  generalFeedbackMessage: 'We appreciate your feedback and would love to hear from you about your experience with Calcium. Let us know what you love and what could be better.',
  generatingLink: 'Creating sharing link...',
  goToDashboard: 'Go to Home',
  healthScoreLabel: 'My HealthIndex',
  totalHealthScore: 'out of 1000',
  healthDataHeader: 'Health Data',
  healthDataBodyMeasurementsSectionHeader: 'Body Measurements',
  healthDataVitalsSectionHeader: 'Vitals',
  healthDataOtherSectionHeader: 'Other',
  healthDashboardHeader: 'Home',
  healthChampionScore: 'Calcium Score',
  healthBadge: {
    badge: {
      title: 'Achievements',
      milestones: 'Milestones',
      taskCompleted: 'Tasks Completed',
      explorer: 'Explorer Badges',
      monthly: 'Challenges'
    },
    unknown: {
      message: 'Status: Unknown',
      status: 'Please complete the required tasks.',
      calculatingStatus: 'Calculating new status.',
      errorStatus: 'An error occurred retrieving the latest status'
    },
    verified: {
      message: 'Status: Okay',
      status: 'Thank you for completing the assessment.'
    },
    unverified: {
      message: 'Status: Warning',
      status: 'Be sure to complete the required tasks. If you reported symptoms, follow CDC guidelines for next steps.'
    },
    updated: 'Updated {0}',
    onDay: 'On {0}',
    dateAtTime: 'MM/DD/YYYY [at] h:mm a',
    notification: {
      congratulations: 'Congratulations!',
      earnedABadge: 'You earned a badge.',
      greatWork: 'Keep up the great work!',
      viewBadge: 'View My Badge',
      viewAchievements: 'View My Achievements'
    }
  },
  healthDataLink: 'Health Data',
  healthData: {
    connect: {
      identityForm: {
        address1Label: 'Address',
        address2Label: 'Suite / Apt #',
        addressCityLabel: 'City',
        addressStateLabel: 'State',
        birthdateLabel: 'Birthdate',
        firstNameLabel: 'First Name',
        lastNameLabel: 'Last Name',
        genderLabel: 'Gender',
        profileLabel: 'Verify Your Identity',
        zipCodeLabel: 'Current Zip Code',
        submitButton: 'Start Verification',
        validationBirthdate: 'Birthdate is invalid',
        validationDay: 'Day must be between 1 and 31',
        validationMonth: 'Month must be between 1 and 12',
        validationZipCode: 'Zip Code must be 5 digits',
        disclaimer: 'By clicking Start Verification you certify that you have legal authorization to access Protected Health Information for this individual.'
      },
      identityVerification: {
        challengeMessage: 'For your security we need a little more information to complete your verification.',
        contactCta: 'Contact Support',
        continueVerificationAction: 'Continue Verification',
        emailSubject: 'Identity Verification Issues',
        emailMessage: 'I am having trouble verifying my identity and need some assistance.',
        introDetails: 'Calcium is dedicated to protecting your privacy. Please help us verify your identity.',
        introHeader: 'Connect Your Health Records',
        processingMessage: 'Processing ...',
        ssnHeader: 'Verify Identity',
        ssnParagraph1: 'In order to make sure we found you, we need you to verify the last four digits of your SSN.',
        ssnParagraph2: 'We use this information for verification only, and will not store it once complete.',
        startVerificationAction: 'Start Verification',
        steps: '1. Identity verification usually takes between five and ten minutes.\n\n3. You may attempt this process **one time**.',
        title: 'Verify Your Identity',
        toastProcessing: 'Processing',
        toastSuccess: 'Identity verified!',
        toastError: 'Could not verify your identity',
        verificationErrorHeader: 'Sorry About That',
        verificationErrorDetails: 'Your identity could not be verified. Please try again.\n\nNote: If you are unable to verify your identity upon this next attempt your account will be locked.',
        verifyIdentiyHeader: 'Verify Identity',
        warning: 'Note: it is important you enter the following information accurately to avoid being locked out of the system. Please avoid distractions during this process.',
        identityVerificationSupportTicketCreated: 'A support ticket has been created and someone from our support team will be contacting you with next steps.',
        noRecordsFoundErrorMessage: 'We successfully connected your profile to our records network, but we couldn\'t locate your records.\n\nThis means that there are currently no electronic records linked to your identity.\n\nDon\'t loose hope. We are continually working on improving our network.​\n\nIn the meanwhile, We\'d love to learn more about your records so that we can improve our ability to locate them.',
        importRecordsFailedErrorMessage: 'Something went wrong while loading your records.​\n\nWe would like to help you resolve this issue.',
        attemptFailedErrorMessage: 'Your identity could not be verified at this time.',
        verify: 'Verify',
        verifyData: 'In order to verify your identity to connect your health records, please complete all information accurately and thoroughly.',
        warningFirstAttempt: 'If we are unable to verify your identity upon this final attempt, your account will be locked. Please verify accuracy.',
        warningSecondAttempt: 'Your account is now locked. A support ticket has been created and our team will be contacting you to resolve.',
        please: 'Please ',
        contactSupport: 'contact support',
        questions: '  with any additional questions.',
        successNavigateMessage: 'You will be navigated back to Health Data page in 10 seconds.'
      },

      status: {
        actionConnect: 'Connect',
        actionConnectLong: 'Connect Health Records',
        complete: 'Complete!',
        connected: 'Connected',
        connecting: 'Connecting...',
        header: 'Health Records',
        lastSynced: 'Synced {0}',
        noData: 'We didn\'t find any of your health records. Don\'t give up hope! We are continuously expanding our network!',
        processing: 'Processing',
        returnToDashboard: 'Return to Home',
        searchComplete: 'Search Complete',
        searching: 'We are now searching our networks for your health information.',
        searchingDisclaimer: 'This could take a while to complete so feel free to continue using the app. We will let you know when we are done.',
        sorry: 'Sorry',
        startedSync: 'Started Sync',
        successDetails: 'Thank you for completing the identity verification process. You identification has been verified and your health records will begin to populate within 24 hours.',
        syncing: 'Syncing...',
        syncDataAction: 'Try Now',
        syncError: 'Something went wrong. Please check back later.',
        viewHealthRecords: 'View Health Records',
        connectingProfile: 'Connecting your profile to the records network',
        locatingRecords: 'Locating your records ',
        loadingRecords: 'Loading your records into Calcium'
      },
      error: {
        checkbox: {
          receivedElectronicRecords: 'I have received electronic records from my doctor(s)',
          notReceivedElectronicRecords: 'I\'ve never received electronic records from my doctor(s)'
        },
        messages: {
          title: {
            connectingFailed: 'Connect failed',
            recordsLocatingFailed: 'Failed to locate records',
            recordsImportingFailed: 'Failed to import records'
          },
          content: {
            connectingFailed: 'We weren\'t able to connect your profile to the records network. This means that none of your doctors have connected your health records to your identity in our network OR there is another person whose identity is similar to yours and our system cannot differentiate between you.',
            recordsLocatingFailed: 'We successfully connected your profile to our records network, but we weren\'t able to locate your records. This means that there are currently no electronic health records that we can link to your identity, OR that the attempt timed out before locating any records for your identity.',
            recordsLocatingFailedDocter: 'It\'s possible that none of your doctors utilize electronic records. We\'d love to learn more about your records so that we can improve our ability to locate them. \n\nContact us by clicking "Get Help" from the menu to tell us about your records.',
            recordsLocatingFailedForSurvey: 'We\'d love to learn more about your records so that we can improve our ability to locate them.',
            recordsImportingFailed: 'We weren\'t able to load your records into Calcium. This means that we located your records, but the system timed out OR one of the network connections failed during the attempt.',
            recordsImportingFailedForSurvey: 'Hmm... It looks like something may be broken.',
            recordsLocatingFailedUnkownError: 'Sorry, we were unable to load your records.',
            recordsLocatingFailedUnkownErrorDescription: 'Something went wrong while retrieving your records. We are working on fixing it.',
            disconnectedEncountersMessage: 'No Encounters have been recorded yet',
            disconnectedProceduresMessage: 'No Procedures have been recorded yet',
            disconnectedLabResultsMessage: 'No Lab Results have been recorded yet',
            disconnectedImmunizationMessage: 'No Immunizations have been recorded yet',
            disconnectedConditionsMessage: 'No Conditions have been recorded yet',
            connectedMessage: 'The feature is temporarily unavailable'
          }
        },
        buttons: {
          tellUsMore: 'Tell us more',
          mayBeLater: 'Maybe later',
          nevermind: 'Nevermind',
          tryAgain: 'Try again now'
        },
        beacon: {
          subject: 'What I was expecting to find in my Calcium EHR',
          body: 'Please tell us a bit about what you were looking for when connecting your EHR.'
        }
      }
    },
    conditions: {
      connectMessage: 'Connect your health records to see your conditions.',
      noConditions: 'No conditions found',
      title: 'Conditions',
      linkText: 'Conditions',
      diagnosed: 'Diagnosed',
      severity: 'Severity',
      reportedBy: 'Reported By',
      remission: 'Remission',
      unspecified: 'Unspecified'
    },
    encounters: {
      connectMessage: 'Connect your health records to see your interactions with physicians and other health care providers.',
      title: 'Encounters',
      diagnoses: 'Diagnoses',
      linkText: 'Encounters',
      location: 'Location',
      noRecords: 'No encounters found',
      provider: 'Provider',
      started: 'Started',
      stopped: 'Stopped'
    },
    carePlan: {
      connectMessage: 'Connect your health records to see your interactions with physicians and other health care providers.',
      title: 'Care Plan',
      linkText: 'Care Plan',
      noRecords: 'No care plan found',
      treatmentPlan: 'Treatment Plan',
      provider: 'Provider',
      carePlanTitle: 'Title',
      expandAction: 'View all',
      collapseAction: 'Collapse'
    },
    immunizations: {
      connectMessage: 'Connect your health records to see your immunization history.',
      dose: 'Dose',
      title: 'Immunizations',
      linkText: 'Immunizations',
      lotNumber: 'Lot #',
      manufacturer: 'Manufacturer',
      noRecords: 'No immunization records found',
      practitioner: 'Practitioner',
      noImmunization: 'Upload COVID-19 Vaccination Card',
      covidVaccination: 'Covid-19 vaccination',
      deletePrompt: 'Are you sure you want to delete this group of images? This operation cannot be undone.',
      deleteImmunization: 'Delete these images?',
      deleteYes: 'Delete image',
      deleteNo: 'Cancel'
    },
    labs: {
      connectMessage: 'Connect your health records to see your diagnostic reports.',
      title: 'Diagnostic Reports',
      linkText: 'Diagnostic Reports',
      noRecords: 'No diagnostic reports found',
      expandAction: 'Expand',
      collapseAction: 'Collapse',
      deletePrompt: 'Are you sure you want to delete this group of images? This operation cannot be undone.',
      deleteLabResult: 'Delete these images?',
      deleteYes: 'Delete image',
      deleteNo: 'Cancel'
    },
    procedures: {
      connectMessage: 'Connect your health records to see your history of medical procedures.',
      title: 'Procedures',
      status: 'Status',
      linkText: 'Procedures',
      location: 'Location',
      noRecords: 'No procedures found',
      provider: 'Provider',
      started: 'Started',
      stopped: 'Stopped'
    },
    summary: {
      totalDays: 'Active Days',
      trendsLastWeek: 'from last week',
      trendsNoChange: 'No change'
    }
  },
  healthScoreDetails: 'The Calcium Score is a calculated number based on many factors related to physical health, mental health, lifestyle, and social factors that can be measured and managed. You can track your Calcium Score on a day-to-day basis while receiving personalized feedback and suggestions for improvement during your evolving healthcare journey.',
  helpscout: {
    heathSync: {
      subject: 'Connect Health Records',
      message: 'Please tell us a bit about what you were looking for when connecting your EHR'
    }
  },
  hipaaAuthorization: 'HIPAA Authorization',
  userConsent: 'User Consent',
  userConsentp1: 'Here is a summary of how we handle your data:',
  userConsentp2: 'I hereby authorize the use or disclosure of protected health information pertaining to me to Calcium LLC. This authorization shall become effective immediately and shall remain in effect for one year from the date of signature unless a different date is specified. The purpose/use of this information is to provide Calcium LLC the ability to best serve me as an authorized user.',
  userConsentp3: 'I authorize Calcium LLC to use my protected health information for marketing purposes including presenting relevant products to me. I understand that if my information would be used for a marketing purpose as defined by the Health Insurance Portability and Accountability Act (HIPAA), Calcium LLC may receive direct or indirect remuneration from a third party. Calcium LLC will not directly sell personal health information to third parties without additional consent.',
  userConsentp4: 'I understand that information used or disclosed may be subject to re-disclosure by the person or class of persons or facility receiving it and would then no longer be protected by federal privacy regulations.',
  userConsentp5: 'I may revoke this authorization by notifying Calcium of my desire to revoke it by notifying Calcium LLC in writing or via email to',
  userConsentp6: '. However, I understand that any action already taken in reliance on this authorization cannot be reversed, and my revocation will not affect those actions. The written revocation is effective upon receipt, except to the extent the disclosing party or others have acted in reliance upon this authorization. I understand that the recipient may not lawfully further use or disclose the health information unless another authorization is obtained from me or unless such use or disclosure is required or permitted by law.',
  userConsentpoint1: 'Your health data will be encrypted and stored on Calcium\'s servers.',
  userConsentpoint2: 'Your data will never be shared without specific consent.',
  userConsentpoint3: 'You may request to remove your account and data at any time.',
  hipaaConsentIntroGreeting: 'Hello,',
  hipaaConsentIntroP1: 'We need authorization to use the personal health information of {0} to provide you the most relevant insights and complete app experience.',
  hipaaReconsentIntroP1: 'Each year, we need to renew your authorization to use the personal health information of {0} to provide you the most relevant insights and complete app experience.',
  hipaaConsentNewVersionIntroP1: 'We\'ve updated our HIPAA authorization form and need your authorization to continue to use the health information of {0} to provide you the most relevant insights and complete app experience.',
  hipaaConsentIntroP2: 'Without your authorization many of our platform\'s most innovative and useful features will be missing from your experience.',
  hipaaConsentP1: 'I have read this form and agree to the uses and disclosures of the information as described.',
  hipaaConsentP2: 'A copy of this authorization is valid as the original. You have a right to a copy of this authorization. By clicking “I Agree”, you are consenting to the above terms in lieu of an original signature on paper.',
  hipaaIntroNewProfileTitle: 'Before we continue..',
  hipaaIntroNewProfileContent: 'We need you to sign our HIPAA agreement for each new profile you create. We know health data can be sensitive and that\'s why we hold ourselves to the highest standard of security.',
  hipaaIntroNewUserTitle: 'Almost there..',
  hipaaIntroNewUserContent: 'But first we need you to sign our HIPAA agreement, we know that health data is personal and can be highly sensitive. That\'s why we hold ourselves to the highest standard of security and believe our users deserve to know how their data is being used.',
  hipaaIntroResignTitle: 'Before we continue..',
  hipaaIntroResignContent: 'We need you to resign our HIPAA agreement, just like you do each year when visiting the doctor. As always we understand health data can be sensitive and that\'s why we hold ourselves to the highest standard of security.',
  hipaaIntroUpdateTitle: 'Before we continue..',
  hipaaIntroUpdateContent: 'We need you to resign our HIPAA agreement, we have made a few changes. As always we understand health data can be sensitive and that\'s why believe our users deserve to know how their data is being used.',
  immunizationForm: 'Immunization Form',
  immunizationTypeCovid: 'Covid-19 vaccination',
  in: 'in {0}',
  inactiveHaveACode: 'Have an Invite Code?',
  inactiveHeader: 'Thanks for Joining',
  inactiveParagraph: 'Stay tuned as we\'ll be adding participants over time. If you have already received an invite code, enter it below for instant access. Otherwise, {0} and we\'ll email you once your access becomes available.',
  inactivePreHeader: 'Enter an Invite Code',
  inactiveRequestCode: 'request an invite code',
  inactiveRequestCodeEmailSubject: 'Request an Invite Code',
  includes: 'includes',
  interestCta: 'Email Us',
  interestEmailSubject: 'Including {0} as a feature would make Calcium a better product.',
  interestMessageCta: 'Would this feature be beneficial to you?',
  interestMessageSubmittedCta: 'Thanks for the feedback! We’d love to hear more about how you’d use it.',
  interestMessageactivity: 'Track and analyze the impact of your daily activity. Input detail about exercise, mindfulness, sleep and other activity directly into the app, and sync connected devices.',
  interestMessageallergies: 'Track and understand your known allergies. Input notes directly into the app, import your medical records and lab results.',
  interestMessagebloodglucose: 'Track and analyze your blood glucose levels. Input readings directly into the app, import your medical records and lab results, and sync connected devices.',
  interestMessagebp: 'Track and understand your blood pressure results. Input readings directly into the app, import your medical records and sync connected devices.',
  interestMessagehealthprograms: 'Get smart recommendations for health-related pathways to help meet your personalized goals.',
  interestMessageimmunizations: 'Track and manage your past, current and upcoming immunizations based on your medical records or direct data input.',
  interestMessagemedications: 'Track and manage your current medications based on your medical records or manually entered list. View drug information, interactions, and refill from specific pharmacy chains.',
  interestMessagenutrition: 'Get a clear picture of your daily eating habits, track progress towards your goals and access tips to improve and maintain a healthy diet.',
  interestMessagereproductive: 'Manage and maintain your reproductive health. Track reproductive processes and functions at all stages of life. Integrate your medical records, 3rd party apps and devices.',
  interestMessagespO2: 'Track and analyze your blood oxygen levels. Input readings directly into the app, import your medical records and sync connected devices.',
  interestMessageshareprofile: 'Share access privileges for profiles with others.',
  interestMessagegroup: 'Create groups, share information and support each other’s progress.',
  interviews: {
    answerYes: 'Yes',
    answerNo: 'No',
    continue: 'Continue',
    complete: 'Complete',
    unknownContentType: 'Unknown content type'
  },
  invitationAccepted: 'Success! Invitation was accepted successfully',
  invitationError: 'Failed to accept the invitation',
  invitationErrorCta: 'Please check your internet connection and try again.',
  invitationErrorToast: 'Failed to accept the invitation',
  isAdminDeleteAccountHeader: 'Unable to delete admin account',
  isAdminDeleteAccountMessage: 'Note: You are an admin in this organization, please remove admin privilege’s prior to deleting your account.',
  join: 'Register',
  journal: {
    linkJournals: 'My Journal',
    noData: 'Click the + symbol to add an entry to your personal health journal.',
    title: 'My Journal',
    createTitle: 'Create Journal Entry',
    formTitle: 'Entry Title (optional)',
    formContent: 'Entry Content (4000 character limit)',
    deletePrompt: 'Are you sure you want to delete this entry? This operation cannot be undone.',
    deleteJournal: 'Delete Journal Entry',
    journalEntry: 'Journal Entry',
    deleteYes: 'Delete entry',
    deleteNo: 'Cancel'
  },
  labResultSubType: 'Covid-19 Test Results',
  labResultForm: 'Lab Result Form',
  linkActivity: 'Exercise',
  linkAddProfile: 'Add Someone',
  linkAllergies: 'Allergies',
  linkLearnMore: 'Calcium',
  linkHome: 'Home',
  linkMedications: 'Medications',
  linkNutrition: 'Nutrition',
  linkObservations: 'Vitals',
  privacyPolicy: 'Privacy Policy',
  eula: 'Terms & Conditions',
  support: 'Support',
  Registration: {
    lastName: 'Last name*',
    firstName: 'First name*',
    userNameRegistration: 'Enter your name',
    userInfo: 'UserInfo',
    setUp: 'Complete set up',
    setupExplanation: 'Calcium can help you stay on top of your health by learning more about you',
    genderExplanation: 'Gender',
    dobExplanation: 'Date of Birth',
    next: 'Next'
  },
  linkProfileList: 'People',
  linkReproductiveHealth: 'Reproductive Health',
  linkSelfProfile: 'Me',
  linkSignIn: 'Sign In',
  linkSignout: 'Sign Out',
  listActivityHeader: 'Exercise',
  listGroupsHeader: 'Groups',
  listMedicationDosesHeader: 'Medication Doses',
  listMedicationsHeader: 'Medications',
  listMoreHeader: 'More Options',
  listPeopleHeader: 'People & Groups',
  listProfileHeader: 'Profiles',
  loading: 'Loading...',
  logButton: 'Add',
  signInError: 'Sign In Error',
  signInErrorMessage: 'Invalid email or password',
  marketplace: {
    label: 'Marketplace',
    sponsoredAds: 'Sponsored',
    noDataText: 'There are curently no items in the marketplace.',
    ads: {
      omronBp7900: {
        title: 'Omron - BP7900, Complete Wireless Upper Arm Blood Pressure Monitor + EKG',
        header: 'Omron - BP7900',
        summary: 'Complete Wireless Upper Arm Blood Pressure Monitor + EKG'
      }
    }
  },
  maxFileValidation: 'You can upload maximum of {0} file(s)',
  menu: 'More',
  more: 'more',
  modal: {
    yes: 'Yes',
    no: 'No'
  },
  fullScreenModel: {
    pathway: 'Take me to the Pathway!',
    pathways: 'Take me to the Pathways!',
    invite: 'You\'ve been invited to a pathway by ',
    invites: 'You\'ve been invited for multiple pathways by ',
    hello: 'Hello',
    hi: 'Hi',
    premium: 'PREMIUM'
  },
  namePlaceholder: 'First and Last',
  nav: {
    title: 'More',
    dashboard: 'Home',
    health: 'Health Data',
    pathways: 'Pathways',
    more: 'More',
    people: 'People and Groups',
    preferences: 'Settings',
    invoice: 'Invoices',
    getHelp: 'Get Help',
    marketplace: 'Marketplace',
    hipaaAuth: 'HIPAA Authorization',
    privacyPolicy: 'Privacy Policy',
    termsConditions: 'Terms & Conditions',
    signOut: 'Sign Out',
    chat: 'Chat',
    medicationTracking: 'Medication Tracking',
    electronicHealthRecord: 'Electronic Health Records',
    healthJournal: 'Health Journal'
  },
  chat911Text: 'If this is a life threatening emergency please dial 911 or proceed to the nearest emergency room.',
  noActivities: 'Log recent exercise',
  noMedications: 'Add your first medication',
  noMedicationsPlaceholder: 'No medications have been recorded yet.',
  noMedicationDosesPlaceholder: 'No medication doses have been recorded today.',
  nonAuthWallPage: {
    nav: {
      about: 'About',
      partners: 'Partners'
    },
    header: 'Empowering people-driven healthcare.',
    subhead: 'Think Healthier.',
    value1: 'Access your electronic health records in real time.',
    value2: 'Track your symptoms and activities.',
    value3: 'Show your doctor what\'s been going on.',
    value4: 'Consolidate your health accessory data.'
  },
  notEnoughInfoForHealthScore: 'Get better insights by adding more data.',
  notVerifiedForInvites: 'Please confirm your email to see invites. If you cannot find the email you can resend it.',
  notifications: {
    label: 'Notifications',
    noDataText: 'No notifications',
    markReadError: 'Error marking notification read',
    markDeletedError: 'Error deleting notification',
    deleteNotification: 'Delete notification',
    clearAll: 'Clear all'
  },
  offlineErrorMainText: 'You Are Offline',
  organizations: {
    admins: 'Administrators',
    adminsDisclaimer: 'These are the administrators of this group. If this information appears suspicious, decline the invite.',
    title: 'Groups',
    createGroupLink: 'Create Group',
    createDescription: 'Create a trusted health group and share important health news with members you invite.',
    joinGroupLink: 'Use Group Invite Code',
    groupNameLabel: 'Group Name',
    groupDescriptionLabel: 'Group Description (optional)',
    statuses: {
      none: 'None',
      pending: 'Pending',
      declined: 'Declined',
      active: 'Active',
      removed: 'Removed'
    },
    roles: {
      none: 'None',
      admin: 'Admin',
      member: 'Member'
    },
    details: {
      header: 'Group Details',
      membersListHeader: 'Memberships'
    },
    loadError: 'An error occurred while loading your groups',
    leavePrompt: 'Are you sure you want to leave {0}?',
    leavePromptForAdmin: 'Are you sure you want to leave {0}?\n\nIf you do, you will still be an admin for {0} but your health data will not be shared with {0}.',
    leaveOrgHeader: 'Leave this group?',
    deleteYes: 'Leave group',
    deleteNo: 'Cancel',
    leaveOrgButton: 'Leave Group',
    organizationInviteHeader: 'Group Invite',
    organizationInviteCodeHeader: 'Invite Code',
    inviteCodeLabel: 'Invite Code',
    inviteCodeIntro: 'Please enter the group\'s invite code.',
    inviteCodeSearching: 'Searching...',
    inviteCodeFound: 'Found!',
    inviteCodeAlreadyUsed: 'Invite code already used.',
    inviteCodeNotFound: 'Invite code not found.',
    removingOrg: 'Leaving {0}...',
    removedOrg: 'Left {0}',
    notVerifiedForInvites: 'Please verify your email to see your invites.',
    viewDetails: 'View Details',
    cannotRespond: 'You cannot accept an invite to join a group with a shared profile. Please choose a different profile to respond to this invite.',
    joinHeader: 'Group {0} Invitation', //group invite
    joinHeaderForProfile: 'Group {0} Invitation For {1}', //group invite for profile
    programInviteHeaderForProfile: 'Pathway invitation from {0} group', //pathway invite from group
    expectationMessage: '{2} is joining as a group {0}.',
    expectationMessageAdmin: 'You have been invited to be an {0} of {1}. Join as {2}.',
    accepting: 'Accepting...',
    accepted: 'Accepted',
    declining: 'Declining...',
    declined: 'Declined',
    membershipAlreadyExists: '{0} is already a member in {1}. Please choose a different profile to respond to this invite.',
    organizationMembershipAlreadyExists: '{0} is already a member.',
    joinChildOrganization: 'Join the group {0}, part of {1}.', //group invite
    joinRootOrganization: 'Join the group {0}.', //group invite
    recommendedByOrg: 'Recommended by {0}',
    learnMore: 'Learn More',
    dataShared: 'The following data will be shared with {0}:',
    permissions: {
      adminHeader: 'Group Administration',
      adminMessageSingle: 'This groups admin is {0}.',
      adminMessageMultiple: 'Admins are {0} and {1}.',
      adminMessageExtended: 'Admins include {0} and {1} more.',
      observationHeader: 'Shared Data',
      observationMessageDefault: 'Question responses will be shared with group admins.',
      observationMessageSingle: 'Question responses and {0} will be shared with group admins.',
      observationMessageMultiple: 'Question responses, {0} and {1} will be shared with group admins.',
      pathwayHeader: 'Enrolled Pathways',
      pathwayMessageSingle: 'You will be enrolled in the {0} pathway.',
      pathwayMessageMultiple: 'You will be enrolled in the {0}, and {1} pathways.',
      choosePathway: 'Choose the pathways you want to enroll',
      selectAllPrograms: 'Select All'
    }
  },
  page: 'Page',
  page404: {
    titleTab: 'Page Not Found',
    heading: 'Oops!',
    subhead: 'We can’t seem to find the page you’re looking for.',
    error: 'Error code: 404',
    goBackButton: 'Home'
  },
  preferencesAdded: 'Added',
  preferencesCelsiusHelper: '(Celsius)',
  preferencesCelsiusLabel: '°C',
  preferencesFahrenheitHelper: '(Fahrenheit)',
  preferencesFahrenheitLabel: '°F',
  preferencesImperialHelper: '(ft, in, lb, oz)',
  preferencesImperialLabel: 'Imperial',
  preferencesMetricHelper: '(m, cm, kg, g)',
  preferencesMetricLabel: 'Metric',
  preferencesOn: 'Yes',
  preferencesOff: 'No',
  preferencesCustomizedDashboard: 'Change Shortcuts',
  preferencesCustomizedDashboardDescription: 'Click on edit button below to change the shortcuts you see on the home page',
  preferencesShowFeaturePreviews: 'Preview Upcoming Features',
  preferencesShowFeaturePreviewsDescription: 'Preview features which are available in the app that are in preview mode. Help us decide what to build next by voting and sharing your opinion.',
  preferencesTemperatureUnitHeader: 'Temperature Units',
  preferencesTitle: 'Settings',
  preferencesUnitHeader: 'Units',
  preview: {
    devicesLabel: 'Connect Wearable Devices',
    devicesName: 'Wearable Devices',
    devicesDetails: 'Connect wearable devices for a clear overview of your progress and access health data reports.',
    healthRecordsLabel: 'Connect Health Records',
    healthRecordsName: 'Health Records',
    healthRecordsDetails: 'Connect your electronic health records to easily view and manage your data.'
  },
  previewGroupMembers: 'Could include you, friends, family, or care providers',
  previewGroupName: 'HealthChampions',
  programs: {
    detailsComplete: 'Pathway Complete',
    detailsCompleteButton: 'Complete Pathway',
    detailsCompletedOnDate: 'Completed {0}',
    detailsDayProgress: 'Day {0} of {1}',
    detailsEndedOnDate: 'Ended {0}',
    detailsEndingDate: 'Ending {0}',
    detailsResources: 'Resources',
    detailsTasksEmpty: 'No tasks',
    detailsTasksAvailableEmpty: 'No tasks to complete yet',
    detailsTasksComplete: '{0} of {1} tasks completed',
    detailsAvailableTaskCompleteSingle: 'Current task is completed',
    detailsAvailableTasksComplete: 'All {0} tasks are completed',
    detailsUnenrollButton: 'Quit Pathway',
    detailsUnenrollHeader: 'Do you want to quit this pathway?',
    detailsUnenrollText: 'Quitting may cause you to lose data connection and progress associated with this pathway.',
    enrollButton: 'Start',
    enrollDuration: 'Duration: {0} days',
    enrollDurationSingular: 'Duration: {0} day',
    enrollDurationShort: '{0} Days',
    enrollDurationShortSingular: '{0} Day',
    enrollHeader: 'Start',
    enrollTitleTab: 'Pathway Enrollment',
    willBeEnrolled: 'You will be enrolled in the {0} pathway',
    sharedDataHeader: 'Shared Data',
    willSeeData: 'Question responses will be shared with group admins.',
    sharedData: 'Pathway asks for {0} data.',
    listHeader: 'Pathways',
    completedListHeader: 'Completed Pathways',
    emptyActivePathWays: 'You currently do not have any active pathways.',
    newPathways: 'To find new pathways to enroll in, select Browse.',
    emptyInvitedPathWays: 'You currently do not have any invited pathways.',
    emptyArchivedPathWays: 'You currently do not have any pathways.',
    inviteOrgQuestion: 'Who invited me?',
    inviteOrganization: 'You were invited by',
    invitePathwayAbout: 'What is this pathway about?',
    questionResponses: 'Question responses',
    invitePathway: 'You have been invited to join',
    sharedPathwayQuestion: 'What will be shared in this pathway?',
    sharedPathwayList: 'You will share the following health data',
    inviteQuestion: 'What should I do if I have questions?',
    inviteAnswer: 'If you have questions about the pathway, please contact ',
    leaderBoard: {
      duration: 'Duration:',
      viewTasks: 'View Tasks',
      myRank: 'My Rank',
      steps: 'Steps',
      me: '(me)',
      floors: 'Floors',
      count: 'Count',
      healthIndex: 'HealthIndex'
    },
    listItemEnrolled: 'Active',
    listPlaceholderNoAvailablePrograms: 'No pathways are currently available',
    listPlaceholderNoCompletedPrograms: 'No pathways have been completed yet',
    listTabAvailable: 'Available',
    listTabEnrolled: 'Enrolled',
    listTabCompleted: 'Completed',
    programShareTitle: 'Calcium Pathway In Progress',
    programShareText: 'I am following the {0} pathway on Calcium',
    programShareDialogTitle: 'Share With Friends',
    programInvite: 'Pathway Invite',
    readyToComplete: 'Archive my pathway',
    reEnrollText: 'Continue your journey; re-enroll in this pathway and take the next steps.',
    reEnrollLabel: 'Re-Enroll',
    reEnrollingLabel: 'Enrolling ...',
    reEnrollSkipLabel: 'Skip',
    reEnrollingSkipLabel: 'Quitting ...',
    reEnrollUpgradeLabel: 'Update',
    reEnrollingUpgradeLabel: 'Updating ...',
    referralListTitle: 'Suggested Pathways',
    successPromptHeader: 'Hey, Nice Work!',
    successPromptCompleteHeader: 'Great Job!',
    successPromptCompleteText: 'You are ready to mark this pathway completed, go for it!',
    successPromptCompleteButton: 'Complete',
    successPromptShareOrReferralCTA: 'Why not share your accomplishments or take the next step and enroll in another pathway?',
    successPromptShareCTA: 'Why not share your accomplishment?',
    successPromptShareTitle: 'Calcium Pathway Complete',
    successPromptShareText: 'I completed the {0} pathway with Calcium',
    successPromptShareDialogTitle: 'Share With Friends',
    taskListTitle: 'Tasks',
    taskListTitleCurrent: 'Available Tasks',
    taskListTitleRemaining: 'Optional Remaining Tasks',
    taskListTitleMissed: 'Missed Tasks',
    taskListTitleCompleted: 'Completed Tasks',
    taskListTitleFuture: 'Future Tasks',
    taskListNextAt: 'Next {0}',
    taskListDueBy: 'Due {0}',
    taskListCompleted: 'Completed {0}',
    tileLink: 'Explore Pathways',
    toastMessageComplete: 'Pathway Complete',
    toastMessageCompleting: 'Completing Pathway',
    toastMessageEnrolling: 'Accepting Pathway',
    toastMessagereenrolling: 'Reenrolling Pathway',
    toastMessageEnrolled: 'Pathway Accepted',
    toastMessageReenrolled: 'Pathway Reenrolled',
    toastMessageLeaving: 'Leaving Pathway',
    toastMessageLeft: 'Pathway Left',
    upgradeAvailableBanner: 'Update to the new version of this pathway.',
    upgradeContentHeader: 'Please confirm pathway update',
    upgradePromptHeader: 'ACTION REQUIRED: YOUR PATHWAY IS OUT OF DATE.',
    upgradePromptText: 'Your current version is out-of-date and needs to be updated so the most accurate data can be captured.',
    upgradePromptHighlightText: 'This will not take any additional time.',
    upgradePromptCTA: 'Update Available',
    upgradeObservationHeaderContent: 'The pathway "{0}" has been updated with a new data response request:',
    upgradeObservationFooterContent1: 'Select "Accept" to continue participation in this pathway with the additional requested data.',
    upgradeObservationFooterContent2: '"Quit pathway" to end your participation in the pathway.',
    weightProgramAction: 'Log weight',
    weightProgramCallToAction: 'Log weight for today',
    weightProgramHistory: 'Weight History',
    weightProgramLinkWeightLoss: 'Weight Loss'
  },
  deleteAccount: 'Delete account',
  deleteAccountTitle: 'Delete your account?',
  deleteAccountMessage: 'Deleting your account will permanently remove all of your data and information.',
  ok: 'OK',
  signoutHeaderMessage: 'Account deletion in progress',
  signoutMessage: 'Note: You have requested to delete this account. You are unable to register/login at this time. Please try again later.',
  register: 'Register',
  registerAlreadyHaveAccount: 'Already have a Calcium account?',
  registerConfirmEmail: 'Thank you for creating your account. Please check your email and confirm your email address.',
  registerLearnMore: 'Learn more about {0}.',
  registerSignup: 'Create a Calcium Account',
  registerThankYou: 'Thank You',
  reload: 'Try Again',
  resendVerification: 'Resend Confirmation Email',
  resetPasswordTitle: 'Reset Password',
  resetPasswordSubtitle: 'Please enter the email address associated with your account.',
  resetPasswordSuccessTitle: 'Thank You',
  resetPasswordSuccessSubtitle: 'Please check your email and click on the link to reset your password.',
  forceResetPasswordText1: 'In an effort to keep your data secure, please use the button below to update your password.',
  forceResetPasswordText2: 'Should you have any questions please contact ',
  forceResetPasswordActionButton: 'Send Password Reset Email',
  resetPasswordSuccessSignInText: 'Navigate to',
  saved: 'Saved!',
  saving: 'Saving...',
  closeConfirmationContent: 'If you exit now, you will lose all progress with this task. Are you sure you want to quit? Click the complete button at the end of this task to submit it.',
  backToTaskLabel: 'Back to task',
  closeTaskLabel: 'Close',
  sending: 'Sending...',
  sent: 'Sent!',
  selectPlaceholder: '- Select',
  sharing: {
    title: 'Share Your Profile Data',
    description: 'Invite another to share administration of your selected profile.',
    inviteEmail: 'Send Email Invitation',
    legalText: 'By clicking the above button you agree to share profile administration with another user who may be able to view and modify this profile\'s health information.',
    noPendingInvites: 'No Pending Profile Invites',
    pendingInvites: 'Pending Invites',
    invitedBy: '{0} would like you to manage {1}\'s profile.',
    incomingTitle: 'Incoming Share Invites',
    sharingTitle: 'Sharing',
    accepted: 'Accepted!',
    declined: 'Declined!',
    pending: 'Pending',
    inviteSentOn: 'Sent on: {0}',
    accessSince: 'Since: {0}',
    whoHasAccess: 'Who Has Access',
    shareToSeeData: 'Manage who in your circle can access and update information about this profile.',
    callToAction: 'Start Sharing Now',
    revoke: 'Stop Sharing',
    delete: 'Delete',
    revokeModalTitle: 'Revoke Access To Profile',
    revokeModalText: 'Are you sure you want to permanently revoke access to {0}?',
    revokeModalDeleteYes: 'Revoke access',
    revokeModalDeleteNo: 'Cancel',
    inviteCancelModalTitle: 'Delete this profile invite?',
    inviteCancelModalText: 'Are you sure you want to delete the invite to {0}?',
    inviteCancelModalDeleteYes: 'Delete invite',
    inviteCancelModalDeleteNo: 'Cancel',
    sharedWithYou: 'Shared With You',
    accepting: 'Accepting...',
    declining: 'Declining...',
    revokingAccess: 'Revoking access...',
    accessRevoked: 'Access revoked!',
    removeProfileModalTitle: 'Remove Profile',
    removeProfileModalText: 'Are you sure you want to remove the profile {0}?',
    deleteYes: 'Remove profile',
    deleteNo: 'Cancel'
  },
  signingIn: 'Signing In...',
  signingOut: 'Signing Out...',
  simpleDateFormat: 'MM/DD/YY',
  simpleDateTimeFormat: 'MM/DD/YY h:mm A',
  simpleTimeFormat: 'h:mm A',
  socialShare: {
    fallbackCopied: 'Copied to Clipboard',
    desktopTitle: 'Copy Link',
    menuShareDialogTitle: 'Invite Friends',
    copyAppLink: 'Copy App Link',
    menuShareText: 'Join me on Calcium'
  },
  sponsored: 'Sponsored',
  states: {
    stateAL: 'Alabama',
    stateAK: 'Alaska',
    stateAS: 'American Samoa',
    stateAZ: 'Arizona',
    stateAR: 'Arkansas',
    stateCA: 'California',
    stateCO: 'Colorado',
    stateCT: 'Connecticut',
    stateDE: 'Delaware',
    stateDC: 'District Of Columbia',
    stateFM: 'Federated States Of Micronesia',
    stateFL: 'Florida',
    stateGA: 'Georgia',
    stateGU: 'Guam',
    stateHI: 'Hawaii',
    stateID: 'Idaho',
    stateIL: 'Illinois',
    stateIN: 'Indiana',
    stateIA: 'Iowa',
    stateKS: 'Kansas',
    stateKY: 'Kentucky',
    stateLA: 'Louisiana',
    stateME: 'Maine',
    stateMH: 'Marshall Islands',
    stateMD: 'Maryland',
    stateMA: 'Massachusetts',
    stateMI: 'Michigan',
    stateMN: 'Minnesota',
    stateMS: 'Mississippi',
    stateMO: 'Missouri',
    stateMT: 'Montana',
    stateNE: 'Nebraska',
    stateNV: 'Nevada',
    stateNH: 'New Hampshire',
    stateNJ: 'New Jersey',
    stateNM: 'New Mexico',
    stateNY: 'New York',
    stateNC: 'North Carolina',
    stateND: 'North Dakota',
    stateMP: 'Northern Mariana Islands',
    stateOH: 'Ohio',
    stateOK: 'Oklahoma',
    stateOR: 'Oregon',
    statePW: 'Palau',
    statePA: 'Pennsylvania',
    statePR: 'Puerto Rico',
    stateRI: 'Rhode Island',
    stateSC: 'South Carolina',
    stateSD: 'South Dakota',
    stateTN: 'Tennessee',
    stateTX: 'Texas',
    stateUT: 'Utah',
    stateVT: 'Vermont',
    stateVI: 'Virgin Islands',
    stateVA: 'Virginia',
    stateWA: 'Washington',
    stateWV: 'West Virginia',
    stateWI: 'Wisconsin',
    stateWY: 'Wyoming'
  },
  bottomNumber: 'Bottom number',
  topNumber: 'Top number',
  goalRange: 'Current Goal Range',
  steptargetTitle: 'Step Goal',
  observationGoalTitle: 'Set Goals',
  setTarget: 'Change Goal',
  currentTarget: 'Current Goal is',
  addTarget: 'Add Goal',
  targetRange: 'Goal Range',
  target: 'Goal',
  minReading: 'Min. Reading',
  maxReading: 'Max. Reading',
  stepTargetMessage: 'Set a daily step goal to help you stay active.',
  setTargetMessage: 'Please set up your goal below to continue.',
  avgstepTarget: 'On average, you walked',
  stepsPerDay: 'steps per day last week',
  tileActivityTitle: 'Exercise - 7 days',
  tileActivityUnits: 'minutes',
  tileBodyMeasurementsTitle: 'BMI',
  noBodyMeasurementsData: 'Add your height and weight',
  tileBodyMeasurementsUnits: '(Overweight)',
  tileMedicationsLabel: 'Synthroid',
  tileMedicationsTitle: 'Medications',
  tileMedicationsUnits: 'hours since last taken',
  timeline: {
    title: '7 Day Timeline',
    noData: {
      header: 'Your Timeline',
      message: 'See all your health data from the last 7 days all in one place.',
      callToAction: 'Try adding your temperature to get started.',
      buttonText: 'Add Temperature',
      linkText1: 'Sync Health Records',
      linkText2: 'Connect a Device',
      WeightData: 'Data unavailable'
    },
    filtersApplied: 'Filters Applied.',
    applyFilters: 'Apply',
    clearFilters: 'Clear',
    summary: {
      title: 'My Summary',
      yourStats: 'See what you accomplished!',
      stepDays: 'Steps/Day',
      deviceNotConnected: 'Device not connected',
      heartRate: 'Resting Heart Rate',
      trendWeek: 'from last week',
      noChangeInData: 'No change',
      fromLastWeight: 'from',
      sleepDays: 'Sleep/Day',
      nutritionDays: 'Calories/Day'
    }
  },
  tooltipAddMedication: 'Add Medication',
  toggleCalendar: 'Calendar',
  toggleList: 'List',
  tryAgain: 'Click here to try again',
  units: {
    unknown: 'N/A',
    beatsPerMinute: 'bpm',
    degreesCelsius: 'ºC',
    degreesFahrenheit: 'ºF',
    centimeters: 'cm',
    inches: 'in',
    kilograms: 'kg',
    pounds: 'lbs',
    meters: 'm',
    steps: 'steps',
    percent: '%',
    internationalUnit: 'IU',
    litersPerMinute: 'l/min',
    gramsPerDeciliter: 'g/dL',
    mgdL: 'mg/dL',
    millimolesPerLiter: 'mmol/L',
    millimetersOfMercury: 'mmHg',
    cubicMetersPerSecond: 'm³/s',
    pascal: 'Pa',
    seconds: 's',
    Calories: 'Cal',
    floorsClimbed: 'Floors'
  },
  userAgent: 'User Agent',
  validateRange: 'Must be greater than from number',
  validationBirthdate: 'Birthdate is invalid',
  validationDate: 'Date is invalid',
  validationDateInPast: 'Date must be in the past',
  validationDateInRange: 'Date is not in the valid range',
  validationInvalidEmail: 'Must be valid email',
  validationInvalidHeight: 'Height is too large',
  validationMaxLength: 'character length max',
  validationMaxCharLength: '{0} character length max',
  validationMaxValue: 'Must be at most',
  validationMinLength: 'character length minimum',
  validationMinValue: 'Must be at least',
  validationNumber: 'Must be a number',
  validationRequired: 'This field is required.',
  validationSsn4: 'Exactly four digits are required',
  validationTimeLessThan1Day: 'Time must be less than 24 hours',
  verifyEmailBody: 'Certain pages cannot be accessed until your email address is confirmed. Please check your email and click on the link to confirm your email address.',
  verifyEmailHeader: 'Please Confirm Your Email Address',
  verifyEmailResentHeader: 'New Email Confirmation Sent',
  verifyEmailBodyMobile: 'If you have confirmed your email and still see this message please restart the app.',
  verifyEmailBodyBrowser: 'If you have confirmed your email and still see this message please refresh the browser.',
  vitalsHeader: 'Vitals',
  welcome: 'Welcome',
  suggestedPathways: 'Suggested Pathways',
  showAll: 'Show all',
  resourceRating: {
    helpful: 'Helpful?',
    yourfeedback: 'Your Feedback',
    content: 'Please tell us why you thought this resource was',
    helpfulContent: 'helpful.',
    notHelpfulContent: 'not helpful.',
    cancel: 'Cancel',
    submit: 'Submit'
  },
  coach: {
    for: 'for',
    months: 'months',
    save: 'Save',
    benefits: 'Kickoff the New Year with these Calcium AI Coach benefits',
    assessment: 'Health assessment by Board Certified Health Coach',
    personalized: 'Personalized, Achievable Smart Goals',
    app: 'Access to the program anytime, anywhere via the Calcium app',
    hipaa: 'HIPAA compliant',
    join: 'And much more! Join today for as little as $10/ month!',
    selectPlan: 'Select a plan',
    start: 'Start Today'
  },
  deletionInProgress: 'We are working on deleting your account, you cannot register/login until the deletion has completed. Please try again later',
  invoice: {
    noData: 'No invoices found',
    verifyHealthRecords: 'Verify your health records',
    identityNotVerified: 'In order to sync your health records, we need your identity. Please complete all the information thoroughly and accurately to avoid being locked out of the system.',
    pay: 'Pay',
    dollar: '$',
    verify: 'Verify now'
  },
  appointment: {
    myAppointment: 'My Appointments',
    provider: 'Provider',
    events: 'My Appointments',
    noRecords: 'No appointments found',
    cancelledAppointment: 'Appointment Cancelled',
    cancelledReason: 'Your appointment was cancelled due to',
    cancelled: 'Cancelled',
    when: 'When',
    where: 'Where',
    appointmentReason: 'Reason for appointment',
    description: 'Description',
    patientInstruction: 'Patient instruction'
  },
  verifyEmail: {
    confirm: 'Confirm your email',
    sentCode: 'Sent a 4 digit code to {0} to confirm.',
    trouble: 'Having trouble?',
    verifying: 'Verifying...',
    verified: 'Verified',
    failed: 'Verification failed'
  },
  deviceName: {
    manual: 'Manual',
    sync: 'Sync from',
    noProviderName: 'Recorded by provider',
    withProviderName: 'Recorded by',
    device: 'device'
  },
  observationConnect: {
    description: 'You have health data that needs to be tracked. Please connect device, activate manual entry or close the health record to proceed.',
    addDevice: 'Add Device',
    manualEntry: 'Manual Entry',
    changeDevice: 'Change Device',
    connectDevice: 'Please connect device(s) to track',
    connectedDevice: 'Connected Devices',
    manualTracking: 'Manually Tracking',
    deactivatedDevice: 'Deactivated',
    manuallyTracked: 'Manually Tracked',
    select: 'Select',
    connectTitle: 'Connect a device to monitor',
    connectDescriptionOne: 'You have a previously connected device that works with {0}. You may choose it or another from our list of supported devices.',
    manualTrackingPathway: 'Manual Pathway Tracking',
    manualTrackingDescription: 'This {0} tracking pathway supports manual tracking allowing you to enter your values each day.',
    manualTrackingConfirm: 'Would you like to Activate Manual Pathway tracking?',
    deactivate: 'Deactivate',
    deactivateTitle: 'Deactivate {0}',
    deactivateDescription: 'We understand you may not have a device today, but if you obtain one in the future you can start tracking {0} data immediately',
    connectedDeviceName: 'currently connected',
    connectTitleText: 'Connect {0} Device',
    deviceSurvey: {
      link: 'I don\'t see my device',
      title: 'Device Update Survey (OPTIONAL)',
      message: 'Please list any information about your device such as manufacturer, device name, model, S/N, etc. and we hope to add your device in a future update.',
      information: 'Device Information'
    }
  },
  observationTypeDescriptions: {
    [ObservationType.BodyArms]: 'Measure of the arms bicep circumference.',
    [ObservationType.BloodGlucose]: 'It\'s important to keep your blood sugar levels in your target range as much as possible to help prevent health problems. Staying in your target range can also help improve your energy and mood.',
    [ObservationType.BloodKetone]: 'A balanced ketone level can improve metabolic and physical health. By measuring your ketone levels, you can determine if you\'re burning fat on Keto.',
    [ObservationType.BloodOxygen]: 'Blood oxygen level is the amount of oxygen circulating in the blood. Every tissue and cell in the body needs a constant supply of oxygen to work properly. So, it\'s important to make sure your blood oxygen level isn\'t too high or low.',
    [ObservationType.Systolic]: 'Start to manage your blood pressure today by making small changes in your everyday life. Your blood pressure is one of the best ways to measure overall health.',
    [ObservationType.Diastolic]: 'Start to manage your blood pressure today by making small changes in your everyday life. Your blood pressure is one of the best ways to measure overall health.',
    [ObservationType.BodyFat]: 'Keeping your body fat percentage at a healthy level can help you from getting serious health risks, regulate temperature, balanced hormone levels and better reproductive health.',
    [ObservationType.BodyChest]: 'Measure of the circumference of the chest.',
    [ObservationType.InstantaneousHeartRate]: 'Getting your heart rate balanced your body move oxygen and blood to your muscles. Measuring your heart rate helps you stay healthy.',
    [ObservationType.Height]: 'Measure of your height.',
    [ObservationType.BodyHips]: 'Measure of the circumference of the inner hips.',
    [ObservationType.Insulin]: 'Healthy, balanced insulin are essential for whole-body health. It\'s important to maintain your insulin levels as much as possible to help prevent health problems.',
    [ObservationType.Nutrition]: 'A healthy, balanced diet is key for anyone with diabetes. Good nutrition not only controls glucose (blood sugar) levels, but also improves cholesterol and blood pressure—both of which can be high for people with diabetes.',
    [ObservationType.PeakFlow]: 'Peak flow can pick up changes in your airways, sometimes before you have any symptoms. Regular peak flow tracking will help you recognize early decreases in airflow and will be used by your caregiver.',
    [ObservationType.Steps]: 'It\'s a good idea to find out how many steps a day you walk now, as your own baseline. Then you can work up toward the goal of 10,000 steps by aiming to add 1,000 extra steps a day every two weeks.',
    [ObservationType.Sleep]: 'Sleep helps your body and brain function properly. Track and balance you sleep patterns to help you reach better health through better sleep.',
    [ObservationType.BodyTemperature]: 'Body temperatures is an early warning sign of infection. Monitoring your body temperature, even when you\'re healthy, can help detect disease early and help you know if it\'s okay to go to work or school.',
    [ObservationType.Weight]: 'Healthy weight helps you feel better and improves your healthy lifestyle. Tracking allows us to be aware of what we\'re eating, how much we\'re moving and how our body weight fluctuates on the scale each day.',
    [ObservationType.BodyThighs]: 'Measure of the circumference of the upper thigh.',
    [ObservationType.BodyWaist]: 'Measure of the circumference of the waist around the belly button.',
    [ObservationType.LabResult]: 'Diagnostic Reports description',
    [ObservationType.Immunization]: 'Immunizations description',
    [ObservationType.Exercise]: 'Regular exercise is good for your healthy physical and emotional life. It can help you prevent from many health problems. It\'s never too late to start building your exercise habits.',
    [ObservationType.HeartRateVariability]: 'Heart\'s variability reflects how adaptable your body can be and consistently create awareness about the body and help you to deal with everyday stressors in a healthy way.Positive considerations in the measurement are that there is no pain, discomfort, or related fear or trepidation for individuals when measurements are taken.',
    [ObservationType.None]: 'None'
  },
  observationsTitle: {
    [ObservationType.BodyArms]: 'Arms',
    [ObservationType.BodyHips]: 'Hips',
    [ObservationType.BodyThighs]: 'Thighs',
    [ObservationType.BodyWaist]: 'Waist',
    [ObservationType.BloodGlucose]: 'Blood Glucose',
    [ObservationType.BloodKetone]: 'Blood Ketone',
    [ObservationType.BloodOxygen]: 'Blood Oxygen',
    [ObservationType.Systolic]: 'Blood Pressure',
    [ObservationType.Diastolic]: 'Blood Pressure',
    [ObservationType.BodyFat]: 'Body Fat',
    [ObservationType.BodyChest]: 'Chest',
    [ObservationType.Height]: 'Height',
    [ObservationType.InstantaneousHeartRate]: 'Heart Rate',
    [ObservationType.Insulin]: 'Insulin',
    [ObservationType.Nutrition]: 'Nutrition',
    [ObservationType.PeakFlow]: 'Peak Flow',
    [ObservationType.Sleep]: 'Sleep',
    [ObservationType.Steps]: 'Steps',
    [ObservationType.BodyTemperature]: 'Temperature',
    [ObservationType.Weight]: 'Weight',
    [ObservationType.LabResult]: 'Diagnostic Reports',
    [ObservationType.Immunization]: 'Immunizations',
    [ObservationType.Exercise]: 'Exercise',
    [ObservationType.HeartRateVariability]: 'Heart Rate Variability',
    [ObservationType.None]: 'None'
  },
  goals: {
    title: 'My Goals',
    Goal: 'Goal:',
    header: 'Create {0} Goal',
    name: 'Goal name',
    description: 'Describe what the goal means to you (optional)',
    continue: 'Continue to Device Selection',
    noGoal: 'You currently do not have any active goals.',
    createNewGoal: 'To create new goal, click "+"',
    goalHelp: 'I need a goal to help with',
    viewAll: 'View all',
    collapseAction: 'Collapse',
    alertTitle: 'Activate {0}',
    alertDescription: 'The {0} device has become disconnected. To continue observation on this pathway, please re-connect your device.',
    pathways: 'Pathways'
  }
};

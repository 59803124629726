import * as auth0 from 'services/auth0-service';
import { isMobile } from 'mobile/helpers';
import * as toastActions from 'app/toaster/actions';
import { localStoragePaths } from 'services/constants';
import * as requestQueueActions from 'app/request/request-queue-actions';
import * as api from 'services/api/profiles-api';
import { saveCurrentBranchData } from 'services/branch-service';
import * as actions from './actions';
import { types } from './identity-types';
import local from 'services/localization/local';
import { getAuthConnection } from 'auth-connections/auth-connection-actions';
import { acceptHipaaConsentPage } from 'profiles/action-creators';
import * as ABTesting_api from  'services/api/ABtesting-api.js';
import * as deleteProfileApi from 'services/api/deleteProfile-api';
import { request } from 'app/request/request-actions';
import { profileDataUpdated } from 'profiles/actions';

export function signIn(email, password, authConnection) {
  return (dispatch) => {
    dispatch(actions.signInRequested());
    localStorage.setItem(localStoragePaths.emailAddress, email);

    const onError = error => {
      dispatch(actions.signInError(error));
    };

    const onEmailUnverified = () => {
      if (!authConnection && !authConnection.length > 0) {
        dispatch(actions.unverifiedEmail());
      }
    };
    auth0.authorize(email, password, onError, onEmailUnverified, authConnection);
  };
}

export function handleProfileDeletionStatus(email) {
  return (dispatch, getState) => {
    const onSuccess = (status) => {
      dispatch(actions.updateProfileDeletionStatus(status));
    };
    return deleteProfileApi.getAccountStatus(email, getState()).then(onSuccess);
  };
}

export function handleSignInCallback() {
  return (dispatch) => {
    var successResult = '';

    const onAuthConnectionCallback = () => {
      dispatch(actions.signInSuccess(successResult));
    };

    const onSuccess = token => {
      successResult = token;
      localStorage.removeItem(localStoragePaths.emailAddress);
      localStorage.setItem(localStoragePaths.chat911TextClosed, 'True');
      dispatch(getAuthConnection(token.idTokenPayload.email, onAuthConnectionCallback));
    };

    const onError = error => {
      dispatch(actions.signInError(error));
    };

    const onEmailUnverified = () => {
      dispatch(actions.unverifiedEmail());
    };

    auth0.authorizeCallback(onSuccess, onError, onEmailUnverified);
  };
}

export function handleSignInCapacitor() {
  return (dispatch) => {

    var successResult = '';

    const onAuthConnectionCallback = () => {
      dispatch(actions.signInSuccess(successResult));
    };

    const onSuccess = token => {
      successResult = token;
      localStorage.removeItem(localStoragePaths.emailAddress);
      if (token.redirect) {
        localStorage.setItem(localStoragePaths.redirect, token.redirect);
      }
      localStorage.setItem(localStoragePaths.chat911TextClosed, 'True');

      dispatch(getAuthConnection(token.idTokenPayload.email, onAuthConnectionCallback));
    };

    const onError = error => {
      dispatch(actions.signInError(error));
    };

    if (!isMobile) {
      onError();
      return;
    }

    auth0.parseHash(onSuccess, onError);
  };
}

export function checkSignIn() {
  return (dispatch) => {
    if (isMobile) {
      return;
    }

    var successResult = '';

    const onAuthConnectionCallback = () => {
      dispatch(actions.signInSuccess(successResult));
    };

    const onSuccess = token => {
      successResult = token;
      dispatch(getAuthConnection(token.idTokenPayload.email, onAuthConnectionCallback));
    };

    const onError = () => {
      dispatch(actions.signInRequired());
    };

    const onEmailUnverified = () => {
      dispatch(actions.unverifiedEmail());
    };

    saveCurrentBranchData(() => auth0.checkSession(onSuccess, onError, onEmailUnverified));
  };
}

export function refreshUserInfo() {
  return (dispatch, getState) => {

    var successResult = '';

    const onAuthConnectionCallback = () => {
      dispatch(actions.refresUserInfoSuccess(successResult));
    };

    auth0.refreshUserData(getState().identity.accessToken)
      .then(result => {
        successResult = result;
        dispatch(getAuthConnection(getState().identity.email, onAuthConnectionCallback));
      });
  };
}

export function loadSelfIdentity() {
  return (dispatch, getState) => {
    dispatch(actions.identityRequested());

    const onSuccess = identity => {
      dispatch(actions.identityLoaded(identity));
    };

    const onError = () => {
      dispatch(actions.identityLoadError());
    };

    const branchData = localStorage.getItem(localStoragePaths.branchLinkData);
    return api.getIdentity(getState(), branchData).then(onSuccess, onError).catch(onError);
  };
}

export function registerUser({ email, password }) {
  const request = {
    email,
    password,
    // defaulting to true
    acceptedPrivacyPolicy: true,
    marketingEmailOptIn: true,
    marketingSourceData: JSON.parse(localStorage.getItem(localStoragePaths.branchLinkData)),
    isForceRegistration: true,
    disableNewProfileNotification: true
  };

  return (dispatch, getState) => {
    dispatch(actions.registeringUser());

    const onSuccess = () => {
      signIn(request.email,request.password)(dispatch);
    };

    const onError = error => {
      dispatch(actions.registerUserError(error));
    };

    return api.registerUser(request, getState()).then(onSuccess).catch(onError);
  };
}

export function resetPassword({ email }) {
  return (dispatch) => {
    dispatch(actions.resettingPassword());

    const onSuccess = () => {
      dispatch(actions.resetPasswordSuccess());
    };

    const onError = () => {
      dispatch(actions.resetPasswordError());
    };

    auth0.changePassword(email, onSuccess, onError);
  };
}

export function updatePreference(preference, value) {
  return (dispatch, getState) => {
    dispatch(actions.updatePreference(preference, value));

    const onError = () => {
      dispatch(toastActions.popErrorToast());
      dispatch(actions.updatePreference(preference, !value));
    };

    return api.updatePreference({ preference, value }, getState()).catch(onError);
  };
}

export function resendVerificationEmail() {
  return (dispatch, getState) => {
    const email = getState().identity.email;
    const message = local.formatString(local.checkEmail, email);

    dispatch(actions.displayCheckEmail(message));
    const action = state => api.resendVerificationEmail(state);

    dispatch(requestQueueActions.queueRequestWithToast(action, types.RESEND_VERIFICATION, null, {
      processingMessage: local.sendingConfirmationEmail,
      successMessage: local.sent
    }, { email }));
  };
}

export function updateUserName(profileId, { userFirstName, userLastName, isForceRegister }) {
  const firstName = userFirstName.trim();
  const lastName = userLastName.trim();
  const request = {
    firstName,
    lastName
  };
  return (dispatch, getState) => {

    dispatch(actions.userNameSaving());

    const onSuccess = () => {
      dispatch(actions.userNameSaved());
      dispatch(actions.updateForceRegistration(isForceRegister));
    };
    const onError = () => {
      dispatch(toastActions.popErrorToast());
    };
    return api.updateUserName(profileId, isForceRegister, request, getState()).then(onSuccess).catch(onError);
  };
}

export function updateUserProfiile(selectedProfileId, request) {
  return (dispatch, getState) => {
    const onSuccess = () => {
      dispatch(acceptHipaaConsentPage(selectedProfileId));
      dispatch(actions.updateForceRegistration(false));
    };
    const onError = () => {
      dispatch(toastActions.popErrorToast());
    };
    return api.updateUserProfile(selectedProfileId, request, getState()).then(onSuccess).catch(onError);
  };
}

export function updateForceRegistration(selfProfileId, request) {
  return (dispatch, getState) => {
    const onSuccess = () => {
      dispatch(actions.updateForceRegistration(request));
    };
    return api.updateForceRegistration(selfProfileId, request, getState()).then(onSuccess);
  };
}

export function loadABTestingSegment() {
  return (dispatch, getState) => {
    const onSuccess = result => {
      dispatch(actions.ABTestingSegment(result));
    };
    const onError = () => {
      dispatch(actions.ABTestingSegmentError());
    };
    return ABTesting_api.getABSegment(getState()).then(onSuccess, onError).catch(onError);
  };
}

export function getIsAdminForAnyOrganization() {
  return (dispatch) => {
    const action = state => api.getIsAdminForAnyOrganization(state)
      .then(isAdmin => {
        dispatch(actions.getIsAdminForAnyOrganizationResult(isAdmin));
      });

    return dispatch(request(action));
  };
}

export function deleteAccount(userData) {
  return (dispatch, getState) => {
    const onSuccess = () => {
      dispatch(actions.deleteAccountCompleted());
    };
    const onError = () => {
      dispatch(actions.deleteAccountError());
    };
    return deleteProfileApi.deleteUserAccount(userData, getState()).then(onSuccess).catch(onError);
  };
}

export function updateProfileDetails(profileData, updatedProfileInfo, requestId) {
  const { currentAddress = {} } = profileData;
  const profile = {
    profileId : profileData.id,
    firstName : updatedProfileInfo.firstName,
    lastName : updatedProfileInfo.lastName,
    dateOfBirth : updatedProfileInfo.dateOfBirth,
    address1 : currentAddress.address1,
    address2 : currentAddress.address2,
    city : currentAddress.city,
    state : currentAddress.state,
    zipCode: updatedProfileInfo.currentZipCode
  };

  if(profileData.gender)
    profile.gender = profileData.gender;

  return (dispatch) => {
    const action = state => api.updateProfileAndCurrentAddress(profile, state)
      .then(() => {
        dispatch(profileDataUpdated(updatedProfileInfo.profileId,
          {
            firstName: updatedProfileInfo.firstName,
            lastName: updatedProfileInfo.lastName,
            dateOfBirth: updatedProfileInfo.dateOfBirth,
            currentAddress: {
              ...currentAddress,
              zipCode: updatedProfileInfo.currentZipCode
            }
          }));
      });

    return dispatch(request(action, requestId));
  };
}

export function verifyEmailWithCode(verificationCode, requestId) {
  return (dispatch) => {
    const action = state => api.verifyEmailWithCode(verificationCode, state)
      .then(() => {
        dispatch(actions.verifiedEmail());
      });

    const requestOptions = {
      showToasts: true,
      processingMessage: local.verifyEmail.verifying,
      successMessage: local.verifyEmail.verified,
      errorMessage: local.verifyEmail.failed
    };

    dispatch(request(action, requestId, {}, requestOptions));
  };
}

import { getErrorCodeText } from 'services/helpers';
import { Group_A, Group_B, Group_C } from 'services/constants';
import { ABSegment } from 'services/enums';

export const getIdentity = state => state.identity || {};

export const getIdentityId = state => {
  return state.identity && state.identity.self && state.identity.self.id;
};

export const getIsEmailVerified = state => !(state.identity.unverifiedEmail);

export const getTokenType = state => getIdentity(state).tokenType;
export const getAccessToken = state => getIdentity(state).accessToken;

export const getRegisterErrorMessage = state => {
  const identity = getIdentity(state);
  return getErrorCodeText(identity.registerError);
};

export const getABTestingSegment = (state, abTestName) =>{
  const SegmentValue = state.identity && state.identity.ABTestingSegment && state.identity.ABTestingSegment.find(data=>data.featureName.toLowerCase() === abTestName.toLowerCase()).segment;
  switch(SegmentValue){
    case ABSegment.Group_A:
      return Group_A;
    case ABSegment.Group_B:
      return Group_B;
    default:
      return Group_C;
  }
};


import React, { useState } from 'react';
import { Page, Button } from 'shared';
import { routePaths } from 'services/routing';
import { Redirect } from 'react-router-dom';
import './ehr.scss';
import { getSubscription, SubsriptionType } from 'preferences/billing/Subscription';

export default function EHR(props) {
  const [redirect, setRedirect] = useState(false);
  const currentSubscription = getSubscription();

  function doPayment() {
    setRedirect(true);
  }

  return (
    <Page
      id='invoice-list'
      backPath={{ to: props.location?.state?.backpath ?? routePaths.root }}
      centerElement='Electronic Health Records'>
      <div id="noDataPlaceholder" className="no-data-placeholder">
        {currentSubscription.type == SubsriptionType.Free ?
          <div>
            <div>
              Upgrade your subscription to access your electronic health records from your hospitals and healthcare providers!
            </div>
            <div className='form-row claim-offer-btn upgrade-button'>
              <Button id='subscribe-button' className="button blue btn btn-primary" onClick={doPayment}>Upgrade</Button>
            </div>
          </div> :
          <div>No EHR Records Vailable</div>}
      </div>
      {redirect ? <Redirect to='/view-plan' /> : null}
    </Page>
  );
}

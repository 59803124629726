import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Page, Error } from 'shared';

class ErrorPage extends Component {
  render() {
    const { id } = this.props;

    return (
      <Page id={id}>
        <Error>
          {this.props.children}
        </Error>
      </Page>
    );
  }
}

ErrorPage.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node
};
ErrorPage.defaultProps = {
  id: 'error-page'
};


export default ErrorPage;

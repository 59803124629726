import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'shared';
import local from 'services/localization/local';
import { isMobile } from 'mobile/helpers';
import './error.scss';

class ErrorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.refreshPage = this.refreshPage.bind(this);
  }

  refreshPage() {
    window.location.reload();
  }

  render() {
    return (
      <div className="errorComponent">
        <div className="errorText">
          {this.props.children}
        </div>
        {!isMobile && (
          <a onClick={this.refreshPage}>
            <div className="link-flex">
              <Icon symbol="refresh" />
              <span>{local.reload}</span>
            </div>
          </a>
        )}
      </div>
    );
  }
}

ErrorComponent.propTypes = {
  children: PropTypes.node
};

export default ErrorComponent;

import React, { useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { localStoragePaths } from 'services/constants';
import { checkSignIn } from 'identity/action-creators';
import SignIn from 'identity/signin/SignIn';
import LayoutContainer from 'app/layout/LayoutContainer';
import IdentityWrapper from 'identity/IdentityWrapper';
import AuthLoader from './AuthLoader';

export default function AuthWrapper() {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(state => state.identity.isSignedIn, shallowEqual);
  const signInRequired = useSelector(state => state.identity.signInRequired, shallowEqual);

  let redirect = null;
  if (isSignedIn) {
    redirect = localStorage.getItem(localStoragePaths.redirect);
    localStorage.removeItem(localStoragePaths.redirect);
  }

  useEffect(() => {
    if (!isSignedIn) {
      dispatch(checkSignIn());
    }
  }, []);

  if (signInRequired) {
    return <SignIn />;
  } else if (redirect) {
    return <Redirect to={redirect} />;
  } else if (isSignedIn) {
    return (
      <IdentityWrapper>
        <LayoutContainer />
      </IdentityWrapper>
    );
  } else {
    return <AuthLoader />;
  }
}

import local from 'services/localization/local';
import { ErrorCodeMessageDict, iPhone, ManufacturerNamesToBeIgnored } from 'services/constants';
import styleVars from 'styles/_variables.scss';
import { capitalize } from 'lodash';

export const getErrorCodeText = (error) => {
  if (!error) {
    return undefined;
  }

  if (error && error.body && error.body.code) {
    return ErrorCodeMessageDict[error.body.code] || local.error;
  }

  return local.error;
};

export const getUniqueId = () =>
  '_' + Math.random().toString(36).substr(2, 9);

export const getColorByGuid = (guid) => {
  const colors = [
    styleVars.colorUi1,
    styleVars.colorUi2,
    styleVars.colorUi3,
    styleVars.colorUi4,
    styleVars.colorUi5,
    styleVars.colorUi6,
    styleVars.colorUi7,
    styleVars.colorUi8,
    styleVars.colorUi9,
    styleVars.colorUi10,
    styleVars.colorUi11,
    styleVars.colorUi12,
    styleVars.colorUi13,
    styleVars.colorUi14,
    styleVars.colorUi15,
    styleVars.colorUi16
  ];

  // Null check
  if (!guid || guid.length < 4) return colors[0];

  const intValue = parseInt(guid.substring(0, 4), 16);
  var index = intValue % colors.length;
  return colors[index];
};

export const isFunction = (variable) => {
  return variable && typeof variable === 'function';
};

export const dynamicSort=(input)=>{
  var sortOrder = 1;
  var property=input;
  if(property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a,b) {
    if(sortOrder == -1){
      return b[property].localeCompare(a[property]);
    }else{
      return a[property].localeCompare(b[property]);
    }
  };
};

export function formatStepsValue( steps ) {
  if(steps !== null && steps !== undefined){
    var formattedSteps = steps.toString();
    if (formattedSteps.length >= 4) {
      formattedSteps = formattedSteps.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    return formattedSteps;
  }
}

export const setLocalStorage = ( name, value ) => {
  localStorage.setItem(name, value);
};

export function getLocalStorage ( name ) {
  return localStorage.getItem(name);
}

export function ConvertGramsToPercent(gram, totalGrams){
  if(gram === 0 || totalGrams === 0){
    return 0;
  }
  return{
    actualValue : ((gram/totalGrams) * 100).toFixed(14),
    displayValue : ((gram/totalGrams) * 100).toFixed(0)
  };
}

export function ConvertPercentToGrams(percent, totalGrams){
  return (totalGrams * percent * 0.01).toFixed(2);
}

export function ConvertActualPercentToGrams(totalGoalCals, goalValuePercent, macroDivider){
  return (((totalGoalCals * goalValuePercent) / 100) / macroDivider).toFixed(0);
}

export function GetManufacturerName(manufacturer){
  if(!manufacturer || ManufacturerNamesToBeIgnored.includes(manufacturer))
    return '';

  const manufacturerDisplayText = manufacturer.toUpperCase() === iPhone.toUpperCase() ? iPhone : CapitalizeText(manufacturer);
  return ` - ${manufacturerDisplayText}`;
}

export function CapitalizeText(text) {

  if(!text)
    return '';

  const words = text.split(' ');

  const capitalizedWords = words.map(word => capitalize(word));

  return capitalizedWords.join(' ');
}

import compileReducers from 'services/compile-reducer';
import * as actions from './action-names';

export const initialState = {
  healthInfo: {},
  loading: false,
  loadError: false,
  loaded: false,
  saving: false,
  saved: false,
  saveError: false
};

const reducers = {};

reducers[actions.HEALTHINFO_REQUESTED] = (state) => ({
  ...state,
  loading: true,
  loaded: false
});

reducers[actions.HEALTHINFO_LOAD_SUCCESS] = (state, payload) => ({
  ...state,
  healthInfo: payload,
  loading: false,
  loaded: true,
  loadError: false
});

reducers[actions.HEALTHINFO_LOAD_ERROR] = (state) => ({
  ...state,
  loading: false,
  loaded: false,
  loadError: true
});

reducers[actions.HEALTHINFO_SAVING] = (state) => {
  return {
    ...state,
    saving: true,
    saved: false,
    saveError: false
  };
};

reducers[actions.HEALTHINFO_SAVE_SUCCESS] = (state, payload) => {
console.log(payload);
  return {
    ...state,
    saving: false,
    saved: true,
    saveError: false
  };
};

reducers[actions.HEALTHINFO_SAVE_ERROR] = (state) => {
  return {
    ...state,
    saving: false,
    saved: false,
    saveError: true
  };
};

reducers[actions.HEALTHINFO_SAVE_RESET] = (state) => {
  return {
    ...state,
    saved: false,
    saveError: false
  };
};

// reducers[actions.HEALTHINFO_DELETE] = (state, payload) => {
//   const medications = state.medications.filter(x => x.id !== payload.medicationId);

//   return {
//     ...state,
//     medications
//   };
// };

export default compileReducers(reducers, initialState);
